/* You can add global styles to this file, and also import other style files */
// Only styles for stand alone app NOT FOR THE MFE PLEASE
@import '~@savvaslearning/lst-style-variables/variables.scss';

html {
    height: 100%;
    overflow: hidden;
}

body {
    margin: 0;
    height: 100%;
    overflow: hidden;
}

.a11yOffScreen {
    position: absolute;
    overflow: hidden;
    width: 0;
    height: 0 ;
    left: -1000px;
    top: 0;
    border: none;
    background:none;
}

::-webkit-scrollbar {
    width: 16px
}

::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background-clip: padding-box;
    border-radius: 8px;
    background-color: #{$gray}
}